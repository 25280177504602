import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/introducing-decoupled-applications",
  "date": "6th March 2023",
  "title": "Decoupled Applications - An Overview",
  "summary": "What are Decoupled Applications? What benefits are there to these approaches?",
  "author": "Griffyn Heels",
  "tag": "Development",
  "tagColor": "blue",
  "tags": [{
    "name": "Decoupled"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The web development industry has been dabbling with decoupled architecture in recent years. It separates an application's presentation layer from the system layer, allowing for more flexibility and innovation in how content is consumed and displayed.`}</p>
    <p>{`Decoupled Drupal applications are built on the CMS, allowing developers to use Drupal as a back-end while leveraging front-end technologies such as JavaScript frameworks, mobile apps, or other web applications.`}</p>
    <h2>{`Key benefits`}</h2>
    <p>{`Decoupled applications provide several advantages for both developers and organisations:`}</p>
    <ol>
      <li parentName="ol">{`Increased flexibility: Decoupling Drupal allows developers to choose the best technology for each part of the application, resulting in faster and more efficient development.`}</li>
      <li parentName="ol">{`Improved user experience: By leveraging the power of JavaScript frameworks, developers can create engaging and responsive interfaces that enhance the user experience.`}</li>
      <li parentName="ol">{`Faster performance: By separating the front end from Drupal's CMS, developers can optimise each part of the application for speed and performance.`}</li>
      <li parentName="ol">{`Scalability: Decoupling allows organisations to scale their applications quickly and efficiently by adding new front-end components or channels.`}</li>
    </ol>
    <h2>{`Different approaches`}</h2>
    <p>{`There are several approaches to implementing decoupled Drupal applications:`}</p>
    <ol>
      <li parentName="ol">{`Headless Drupal: Involves using Drupal as a back-end to provide content through a RESTful API, while the front-end is built with a JavaScript framework such as React, Angular, or Vue.`}</li>
      <li parentName="ol">{`Progressive Decoupling: Uses Drupal for back-end and front-end development but still leverages JavaScript frameworks for specific features.`}</li>
      <li parentName="ol">{`Fully Decoupled: Using Drupal exclusively for back-end development, with the front-end developed using a separate system entirely.`}</li>
    </ol>
    <h2>{`Pros and cons`}</h2>
    <h3>{`1. Headless Drupal`}</h3>
    <p>{`Pros:`}</p>
    <ul>
      <li parentName="ul">{`Flexibility: Developers can choose any front-end technology.`}</li>
      <li parentName="ul">{`Reusability: Content can be easily consumed and displayed across multiple channels (e.g. digital signage).`}</li>
    </ul>
    <p>{`Cons:`}</p>
    <ul>
      <li parentName="ul">{`Complexity: Requires extra development work to build and maintain the front-end.`}</li>
      <li parentName="ul">{`Limited Drupal-specific features: Some features may not be available in the front-end.`}</li>
    </ul>
    <h3>{`2. Progressive Decoupling`}</h3>
    <p>{`Pros:`}</p>
    <ul>
      <li parentName="ul">{`Improved user experience: Developers can leverage the power of JavaScript frameworks while still using Drupal's robust CMS features.`}</li>
      <li parentName="ul">{`Easier maintenance: Developers can use Drupal's back-end to maintain content while allowing custom front-end development.`}</li>
    </ul>
    <p>{`Cons:`}</p>
    <ul>
      <li parentName="ul">{`Complexity: Requires extra development work to integrate the CMS and the front-end.`}</li>
      <li parentName="ul">{`Performance: The front-end can slow down if not optimised properly.`}</li>
    </ul>
    <h3>{`3. Fully Decoupled`}</h3>
    <p>{`Pros:`}</p>
    <ul>
      <li parentName="ul">{`Complete flexibility: Developers can build the front-end using any preferred technology.`}</li>
      <li parentName="ul">{`Reduced complexity: Developers can focus solely on front-end development without worrying about Drupal's CMS features.`}</li>
    </ul>
    <p>{`Cons:`}</p>
    <ul>
      <li parentName="ul">{`Limited Drupal-specific features: Powerful CMS features are unavailable in the front end.`}</li>
      <li parentName="ul">{`Maintenance: Content may be harder to manage without an explicitly built front-end.`}</li>
    </ul>
    <h2>{`Conclusion`}</h2>
    <p>{`Decoupled Drupal applications offer a flexible and scalable approach to web development that can help organisations achieve their business goals faster and more efficiently. While the different implementation approaches have pros and cons, the benefits of decoupling Drupal from the front-end are numerous, making it an attractive option for modern web development.`}</p>
    <p>{`From a resourcing perspective, when taking a decoupled approach front end-developers don't need Drupal-specific experience and can be CMS agnostic. `}</p>
    <p>{`If you're interested in learning more about decoupled Drupal applications or need support implementing decoupled Drupal in your application, please don't hesitate to get in touch with our Skpr team.`}</p>
    <p>{`We'll happily provide a demo of decoupled applications hosted on Skpr and help you achieve your web development goals.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      